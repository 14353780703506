import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Slick from 'react-slick';

const PrevArrow = props => {
  const { onClick } = props;
  return (
    <button
      className={`slider-arrow slide-prev ${
        onClick === null ? 'slider-arrow-disabled' : ''
      }`}
      onClick={onClick}
    >
      <span>&#8249;</span>
    </button>
  );
};

const NextArrow = props => {
  const { onClick } = props;
  return (
    <button
      className={`slider-arrow slide-next ${
        onClick === null ? 'slider-arrow-disabled' : ''
      }`}
      onClick={onClick}
    >
      <span>&#8250;</span>
    </button>
  );
};

const defaultSettings = {
  dots: false,
  arrows: true,
  autoPlay: false,
  draggable: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  swipeToSlide: true,
  swipe: true,
  centerMode: false,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

const Slider = ({ id, settings, children, goto }) => {
  const slider = useRef(`slider`);

  useEffect(() => {
    slider.current.slickGoTo(goto);
  }, [goto]);

  return (
    <div className="w-full">
      <Slick
        ref={slider}
        {...defaultSettings}
        {...settings}
        className={`${id}`}
      >
        {children}
      </Slick>
    </div>
  );
};

Slider.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  settings: PropTypes.object,
  goto: PropTypes.number,
};

Slider.defaultProps = {
  id: `${parseInt(Math.random() * 100000000)}`,
  settings: {},
  goto: 0,
};

export default Slider;
